import { hasWindow } from '@services/http.service';
import {
    CREATE_AISAFE_REFUND_OVERSUBSCRIPTION_REPAYMENT,
    CREATE_OFFLINE_REFUND_OVERSUBSCRIPTION_REPAYMENT,
    GET_PAYMENT_BY_INVESTMENT_ID_URL,
    GET_PAYMENT_BY_STATUS_URL,
    GET_PAYMENT_GATEWAY_BY_OPPORTUNITY_ID,
    GET_PAYMENT_GATEWAY_MID_DETAILS_URL,
    GET_PAYMENT_ID_URL,
    POST_WALLET_PAYMENT,
    UPDATE_WALLET_PAYMENT,
} from '@services/urls/payment';
import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';

export const getPaymentGateWaysMidDetails = (ctx = null) => {
    const url = GET_PAYMENT_GATEWAY_MID_DETAILS_URL();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getPaymentGateWay = (opportunityId, ctx = null) => {
    const url = GET_PAYMENT_GATEWAY_BY_OPPORTUNITY_ID(opportunityId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getPaymentById = (id, ctx = null) => {
    const url = GET_PAYMENT_ID_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
export const getPaymentByInvestmentId = (investmentId, ctx = null) => {
    const url = GET_PAYMENT_BY_INVESTMENT_ID_URL(investmentId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const postWalletPayment = (data) => {
    const url = POST_WALLET_PAYMENT();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const updateWalletPayment = (data) => {
    const url = UPDATE_WALLET_PAYMENT();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};

export const createAiSafeRefundOverSubscriptionRepayment = (data) => {
    const url = CREATE_AISAFE_REFUND_OVERSUBSCRIPTION_REPAYMENT();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const createOfflineRefundOverSubscriptionRepayment = (data) => {
    const url = CREATE_OFFLINE_REFUND_OVERSUBSCRIPTION_REPAYMENT();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getPaymentByStatus = (status, limit, page, ctx = null) => {
    const url = GET_PAYMENT_BY_STATUS_URL(status, limit, page);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
