import { supportEmail } from '@helpers/constants';

export const minInvestmentAmountError =
    'Investment amount should be greater than or equal to';

export const maxInvestmentAmountError =
    'Investment amount should be less than or equal to';

export const investmentAmountEqualToError =
    'Investment amount should be equal to';

export const minInvestmentUnitError =
    'Investment units should be greater than or equal to';

export const maxInvestmentUnitError =
    'Investment units should be less than or equal to';

export const investmentUnitEqualToError =
    'Investment units should be equal to';

export const suggestionError =
    'Your investment will cause the remaining investment amount in the deal to be lower than the allowed amount. Please choose from an option below, or enter another amount'

export const amountToBeMultipleOfError = 'Amount entered must be a multiple of';

export const updatePaymentScheduleSuccessMessage =
    'Out of schedule payment has been successfully marked as received';

export const LIMIT_TO_SHOW_WALLET_INFO = 300000;

export const investorNotEligibleError = `You are not allow to invest in this opportunity. If you have any questions, please contact ${supportEmail}`