import { mobileDeviceTypes, WalletName } from './constants';
import {
    amountToBeMultipleOfError,
    investmentAmountEqualToError,
    investmentUnitEqualToError,
    LIMIT_TO_SHOW_WALLET_INFO,
    maxInvestmentAmountError,
    maxInvestmentUnitError,
    minInvestmentAmountError,
    minInvestmentUnitError,
    suggestionError,
} from '../helpers/messages';

import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
/**
 * utility method that
 * return filtered header menu according to user
 */
export const filterHeaderMenu = (auth, menu, investorCache) => {
    if (menu) {
        let filteredMenuItems = [...menu];
        if (auth?.token) {
            if (
                investorCache?.showJiraafSafe == null ||
                !investorCache?.showJiraafSafe
            ) {
                filteredMenuItems = filteredMenuItems.filter(
                    (each) => each?.name !== WalletName
                );
            }
            if (
                investorCache?.showReferral != null &&
                !investorCache?.showReferral
            ) {
                filteredMenuItems = filteredMenuItems.filter(
                    (each) => each?.name !== 'Refer & Earn'
                );
            }
        } else {
            filteredMenuItems = filteredMenuItems.filter(
                (each) => each?.name !== WalletName
            );
        }
        return filteredMenuItems;
    } else {
        return [];
    }
};

/**
 * utility method that
 * return is Wallet Active or not
 */
export const isWalletActive = (wallet) => {
    return wallet?.isActive || false;
};

/**
 * utility method that
 * return is Valid Response and Entity
 */
export const isValidResponseEntity = (response) => {
    return (response?.status && response?.entity) || false;
};

/**
 * utility method that
 * return is Kyc Verified or not
 */
export const isKycVerified = (investor) => {
    return investor?.kycStatus === 'Verified';
};

/**
 * utility method that
 * return is Kyc Status
 */
export const getKycStatus = (investor) => {
    return investor?.kycStatus || 'Pending';
};
/**
 * utility method that
 * return is demat present or not
 */
export const isDematPresent = (investor) => {
    return !!investor?.investorDemat?.dematAccount || false;
};

/**
 * utility method that
 * return is demat account number
 */
export const getDematAccountNumber = (investor) => {
    return investor?.investorDemat?.dematAccount || 'N/A';
};

/**
 * utility method that
 * return is account number
 */
export const getAccountNumber = (investor) => {
    return investor?.bankDetails?.accountNumber || 'N/A';
};

/**
 * utility method that
 * return is Nri or not
 */
export const isNriInvestor = (investor) => {
    return investor?.isNri || false;
};

/**
 * utility method that
 * return investor virtualAccountNumber
 */
export const getVan = (investor) => {
    return investor?.virtualAccountNumber || 'N/A';
};

/**
 * utility method that
 * return is ifa
 */
export const isIfa = (auth) => {
    return auth?.entityType === 'ifa';
};

/**
 * utility method that
 * return is not ifa
 */
export const isNotIfa = (auth) => {
    if (auth?.entityType) {
        return auth?.entityType !== 'ifa';
    }
    return false;
};

/**
 * utility method that
 * return show Referral or not
 */
export const showReferral = (auth) => {
    return !isIfa(auth);
};

/**
 * utility method that
 * return show altSafe or not
 */
export const showJiraafSafe = (investor, investorCache) => {
    return isKycVerified(investor) && (investorCache?.isWalletActive || false);
};

/**
 * utility method that
 * return wallet amount
 */
export const getWalletAmount = (wallet) => {
    return wallet?.amount || 0;
};

/**
 * utility method that
 * return Reinvestable wallet amount
 */

export const getReinvestableAmount = (wallet) => {
    return wallet?.reinvestableAmount || 0;
};

/**
 * in case of kyc not auto verified, but later verified then
 * if getInvestor api sends kyc verified then
 * update menus and investorCache
 */
export const isKycUpdated = (investorCache, investor) => {
    return !!(
        investorCache?.isKycVerified != null &&
        !investorCache?.isKycVerified &&
        isValidResponseEntity(investor) &&
        isKycVerified(investor?.entity)
    );
};

/**
 * in case of kyc not auto verified, but later verified then
 * if wallet is active for the investor -> kyc verified then
 * update menus and investorCache
 */
export const isWalletUpdated = (investorCache, walletResponse) => {
    return !!(investorCache && (investorCache?.isKycVerified == null || !investorCache?.isKycVerified) && isWalletActive(walletResponse?.entity));
};

/**
 * A message is displayed for a demat account
 * if KYC is not verified.
 */
export const showKycDematAccordion = (accordionType, kycStatus) =>
    accordionType === 'DematDetails' && (!kycStatus || kycStatus === 'Pending');

/**
 * Return either first value or second or default
 */
export const getEitherOrDefaultValueOf = (firstValue, secondValue) => {
    return (firstValue || secondValue || 0);
};

/**
 * Return if entered unit is eligible to invest or not
 */
export const isValidUnits = ({ currentUnits, minInvestmentUnit, remainingUnits }) => {
    /**
     * Validation errors breaching min and remaining units
     */
    if (currentUnits === remainingUnits) {
        return null;
    } else if (remainingUnits <= minInvestmentUnit) {
        return `${investmentUnitEqualToError} ${remainingUnits}`;
    } else if (currentUnits > remainingUnits) {
        return `${maxInvestmentUnitError} ${remainingUnits}`;
    } else if (currentUnits < minInvestmentUnit) {
        return `${minInvestmentUnitError} ${minInvestmentUnit}`;
    }
    return null;
};


/**
 * Return if entered investment amount is eligible to invest or not
 * in case of suggestions returns 2 suggestions
 */
export const isValidAmount = ({
    currentAmount,
    minAmount,
    maxAmount,
    remainingAmount,
    investmentMultiple,
    isFTI = false,
    minAmountForFTI,
}) => {

    const minInvestmentAmount = isFTI ? minAmountForFTI : minAmount;

    /**
     * Validation errors breaching min and max
     */
    if (currentAmount < minInvestmentAmount) {
        return `${remainingAmount === minInvestmentAmount
            ? investmentAmountEqualToError
            : minInvestmentAmountError} ${getNumberWithComma(minInvestmentAmount)}`;
    } else if (currentAmount > maxAmount) {
        return `${remainingAmount === minInvestmentAmount
            ? investmentAmountEqualToError
            : maxInvestmentAmountError} ${getNumberWithComma(maxAmount)}`;
    }


    if (remainingAmount !== currentAmount) {
        /**
         * Suggestion errors
         */
        if (remainingAmount - currentAmount < minAmount) {
            /**
             * minSuggestion calculation which is nearest to investment multiple
             */
            let minSuggestion = (maxAmount - minAmount);
            minSuggestion -= (minSuggestion % investmentMultiple);
            const suggestions = [minSuggestion, maxAmount];

            return {
                "error": suggestionError,
                "suggestions": suggestions,
            };
        }
        /**
         * Investment multiple errors
         */
        if (currentAmount % investmentMultiple !== 0) {
            return `${amountToBeMultipleOfError} ${investmentMultiple}`;
        }
    }

    return null;
};

/**
 * A regex to check +ve amount upto 2 decimal places
 */
export const regexAmount = (value) => {
    return (/^(\s*|[1-9]\d*(\.\d{0,2})?)$/.test(value)) ? value : 0;
};

/**
 * A regex to check +ve number of units
 */
export const regexUnits = (value) => {
    return (/^(\s*|[1-9]\d*)$/.test(value)) ? value : 0;
};

/**
 * return number with coma in locale
 */
export const getNumberWithComma = (number) => {
    return (number || 0).toLocaleString('en-In');
};

/**
 * return investment amount as per NCD or NON NCD.
 */
export const getInvestmentAmount = ({ isNCD, investmentUnits, dailyDebenturePrice, investmentAmount }) => {
    if (isNCD) {
        return Math.round(Number(investmentUnits) * dailyDebenturePrice)
    }
    return investmentAmount;
}

/**
 * show large investment alert
 */
export const showLargeInvestmentAlert = ({ isWalletActive, walletAmount, investmentAmount }) => {
    return (isWalletActive &&
        walletAmount < investmentAmount &&
        investmentAmount >= LIMIT_TO_SHOW_WALLET_INFO
    );
};


/**
 * reduce Investment Unit by 1
 */
export const decrementInvestmentUnit = ({ investmentUnits, minInvestmentUnit, remainingUnits }) => {
    if (investmentUnits > remainingUnits || remainingUnits < minInvestmentUnit) {
        return remainingUnits;
    } else if (investmentUnits < minInvestmentUnit) {
        return minInvestmentUnit;
    } else if (investmentUnits > minInvestmentUnit) {
        return investmentUnits - 1
    }
    return false;
};

/**
 * increase Investment Unit by 1
 */
export const incrementInvestmentUnit = ({ investmentUnits, minInvestmentUnit, remainingUnits }) => {
    if (investmentUnits > remainingUnits || remainingUnits < minInvestmentUnit) {
        return remainingUnits;
    } else if (investmentUnits < minInvestmentUnit) {
        return minInvestmentUnit;
    } else if (investmentUnits < remainingUnits) {
        return investmentUnits + 1;
    }
    return false;
};

/**
 * return payment gateway name
 */
export const getPaymentGatewayName = (response) => {
    if (isValidResponseEntity(response)) {
        const pgName = response?.entity[0]?.name?.toLowerCase() || '';
        if (response?.entity?.length === 1) {
            return pgName;
        }
    }
    return '';
};

/**
 * show if investment via to wallet possible
 */
export const showWalletSuggestion = ({ investmentAmount, walletAmount }) => {
    return ((walletAmount > 0) && walletAmount >= investmentAmount);
};

/**
 * get html tag id
 * example -> Support and Data to support-and-data
 */
export const getId = (string) =>
    string.split(' ').join('-').replace('&', 'and').toLowerCase();

/**
 * Append query parameters from object to the url path and return the new path
 */
export const appendQueryParametersToPath = ({ queryParamObject, path }) => {
    if (typeof queryParamObject === 'object' && Object.keys(queryParamObject).length > 0 && path) {
        let queryString = '?';
        for (let key in queryParamObject) {
            if (queryString !== '?') {
                queryString += '&'
            }
            queryString += `${key}=${queryParamObject[key]}`;
        }

        return `${path}${queryString}`;
    }
    return path ? path : '';
}

export const digioEnvironment = [
    'production',
    'uat',
    'qa',
    'qaslave',
    'qamobile',
    'staging',
].includes(process.env.NODE_ENV)
    ? 'production'
    : 'sandbox';

// Used to replace anchor tags with spans of the given element and its children
export function replaceAnchorsWithSpans(element, onClickCallback) {
    function traverseAndReplace(node) {
        if (node instanceof HTMLAnchorElement) {
            const span = document.createElement('span');
            span.innerHTML = node.innerHTML;
            // Copy attributes (including styles and classNames)
            for (const { name, value } of node.attributes) {
                span.setAttribute(name, value);
            }
            span.onclick = function () {
                onClickCallback(node.getAttribute('href'));
            };
            node.parentNode.replaceChild(span, node);
        }

        if (node.childNodes && node.childNodes.length > 0) {
            for (let i = 0; i < node.childNodes.length; i++) {
                traverseAndReplace(node.childNodes[i]);
            }
        }
    }

    traverseAndReplace(element);
}

// removes query string and returns url with a forward slash
export function removeQueryStringFromUrl(url) {
    try {
        const parsedURL = new URL(url);
        parsedURL.search = ''; // Setting the search property to an empty string removes the query string
        let returnStr = parsedURL.toString();
        if (returnStr[returnStr.length - 1] != '/') {
            returnStr = `${returnStr}/`;
        }
        return returnStr;
    } catch (error) {
        return '';
    }
}

export const getMobileOS = () => {
    const userAgent = navigator.userAgent.toString().toLowerCase();
    if (userAgent.includes("android")) {
        return mobileDeviceTypes.android;
    }
    if (userAgent.includes("iphone") || userAgent.includes("ios")) {
        return mobileDeviceTypes.ios;
    }
    return mobileDeviceTypes.unknown;
};

export const cn = (...inputs) => {
    return twMerge(clsx(inputs));
}

export const isNotNullOrUndefined = (value) => value !== null && value !== undefined;


/*
formatSecondsToTimeString(86400) // "24 hours"
formatSecondsToTimeString(10800) // "3 hours"
formatSecondsToTimeString(12600) // "3 hours 30 mins"
formatSecondsToTimeString(12630) // "3 hours 30 mins"
formatSecondsToTimeString(1800) // "30 mins"
formatSecondsToTimeString(1830) // "30 mins"
formatSecondsToTimeString(60)    // "1 min"
formatSecondsToTimeString(50)    // "50 seconds"
*/
export const formatSecondsToTimeString = (seconds) => {
    if(!seconds) return '';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hours > 0) {
        if (minutes > 0) {
            return `${hours} hours ${minutes} mins`;
        } else {
            return `${hours} hours`;
        }
    } else if (minutes > 0) {
        return `${minutes} ${minutes > 1 ? 'mins' : 'min'}`;
    } else {
        return `${secs} seconds`;
    }
}

export const parseQueryString = (queryString) => {
    if (queryString.startsWith('?')) {
        queryString = queryString.substring(1);
    }

    if (!queryString) {
        return {};
    }

    const pairs = queryString.split('&');

    const queryParams = pairs.reduce((acc, pair) => {
        const [key, value] = pair.split('=');
        acc[decodeURIComponent(key)] = value ? decodeURIComponent(value) : '';
        return acc;
    }, {});

    return queryParams;
}

/**
 * Append query parameters from object to the url path and return the new path,
 * if query exist, it will not add again.
 */
export const addQueriesToPath = ({ queryParamObject, path }) => {
    // Check if queryParamObject is a valid object (not null or undefined)
    if (queryParamObject && typeof queryParamObject === 'object' && Object.keys(queryParamObject).length > 0 && path) {
        const [basePath, existingQueryString] = path.split('?');
        const searchParams = new URLSearchParams(existingQueryString);

        for (let key in queryParamObject) {
            if (!searchParams.has(key)) {
                searchParams.append(key, queryParamObject[key]);
            }
        }

        const newQueryString = searchParams.toString();
        return newQueryString ? `${basePath}?${newQueryString}` : basePath;
    }

    return path ? path : '';
};

/**
 * Remove query parameters from object to the url path and return the new path
 */
export const removeQueriesFromUrlIfExist = ({ url, queryParamObject }) => {
    if (!url || typeof queryParamObject !== 'object' || queryParamObject === null) {
        return url;
    }

    const [path, queryString] = url.split('?');

    if (!queryString) return url;

    const queryParams = new URLSearchParams(queryString);

    Object.keys(queryParamObject).forEach((key) => {
        if (queryParams.get(key) === String(queryParamObject[key])) {
            queryParams.delete(key);
        }
    });

    const newQueryString = queryParams.toString();

    return newQueryString ? `${path}?${newQueryString}` : path;
};
