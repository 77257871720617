import {UrlParamsReplace} from './index';

export const GET_EXISTING_ACCOUNT_URL = (mobile) => UrlParamsReplace('/existing-account?mobile=:mobile', {mobile});

export const GET_EXISTING_ACCOUNT_MULTIPLE_DATA = (mobile) => UrlParamsReplace('/multiple-data?mobile=:mobile', {mobile});

export const GET_EXISTING_ACCOUNT_ADMIN_URL = (mobile) => UrlParamsReplace('/admin/existing-account?mobile=:mobile', {mobile});

export const GET_EXISTING_PAN_URL = (pan) => UrlParamsReplace('/existing-pan?pan=:pan', {pan});

export const GET_USERS_URL = (page, limit, searchText) => UrlParamsReplace('/users?page=:page&limit=:limit&searchText=:searchText', {
    page, limit, searchText,
});

export const GET_USER_DATA_URL = (id) => UrlParamsReplace('/users/:id', {
    id,
});

export const POST_LOGOUT_USER_URL = () => UrlParamsReplace('/v2/logout')

export const PUT_DEACTIVATE_USER_URL = (id) => UrlParamsReplace('/users/:id/deactivate', {id});

export const POST_DEACTIVATE_INDIVIDUAL_INVESTOR_URL = (id) => UrlParamsReplace('/investors/:id/deactivate', {id});

export const GET_KYC_NUDGE_DETAILS = (userId) => UrlParamsReplace('/kyc-nudge/:userId', {userId});

export const CREATE_KYC_NUDGE_DETAILS = () => UrlParamsReplace('/kyc-nudge');

export const UPDATE_KYC_NUDGE_DETAILS = (userId) => UrlParamsReplace('/kyc-nudge/:userId', {userId});

export const GET_USER_GCLID_RECORD = (userId) => UrlParamsReplace('/gclid-record/:userId', {userId});

export const CREATE_USER_GCLID_RECORD = () => UrlParamsReplace('/gclid-record');

export const GET_USER_LEVEL_NUDGES = (userId) => UrlParamsReplace('/users/:userId/nudges', { userId });

export const POST_VERIFY_USER_EMAIL = () => UrlParamsReplace('/users/verify-email');

export const POST_UPDATE_USER_EMAIL = () => UrlParamsReplace('/users/update-email');

export const GET_USER_URL = (userId) => UrlParamsReplace('/user/:userId', { userId });
