import { hasWindow } from '@services/http.service';
import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import {
    GET_CONFIGURATIONS_URL,
    GET_FESTIVE_SEASON_PROSPERITY_CONFIGURATIONS_URL,
    GET_OPEN_CONFIGURATIONS_URL,
} from '@services/urls/configuration';

export const getOpenConfigurationByEntityType = (entityType, ctx = null) => {
    const url = GET_OPEN_CONFIGURATIONS_URL(entityType)
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url, ctx);
};

export const getConfigurations = (configurationType, entityType, ctx) => {
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(
            GET_CONFIGURATIONS_URL(configurationType, entityType)
        );
    }
    return SsrHttpService.getWithAuth(
        GET_CONFIGURATIONS_URL(configurationType, entityType),
        ctx
    );
};

export const getFestiveSeasonConfiguration = (ctx = null) => {
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(
            GET_FESTIVE_SEASON_PROSPERITY_CONFIGURATIONS_URL()
        );
    }
    return SsrHttpService.getWithOutAuth(
        GET_FESTIVE_SEASON_PROSPERITY_CONFIGURATIONS_URL(),
        ctx
    );
}
