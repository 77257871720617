import { UrlParamsReplace } from '@services/urls/index';

export const GET_OPEN_CONFIGURATIONS_URL = (entityType) =>
    UrlParamsReplace('/openConfigurations/:entityType', {entityType});

export const GET_CONFIGURATIONS_URL = (configurationType, entityType) =>
    UrlParamsReplace(
        '/configurations/:configurationType/entityTypes/:entityType',
        {
            configurationType,
            entityType,
        }
    );

export const GET_FESTIVE_SEASON_PROSPERITY_CONFIGURATIONS_URL = () =>
    UrlParamsReplace(
        '/openConfigurations/General/entityTypes/FestiveSeasonOfProsperity'
    );
